import dynamic from 'next/dynamic'
import { useState, useEffect } from 'react'
import styleConfig from '@/styles/style-config'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'

const ContentTextWithImageBlock = dynamic(
  () => import('@/components/content/content-text-with-image-block')
)

const ContentSideBySideCard = (props) => {
  const [headline, setHeadline] = useState(props.headline)

  const backgroundColor = props?.backgroundColor || 'FFFFFF'
  const textColor = props?.textColor || '000000'
  const contentBlocks = props?.contentBlocksCollection?.items

  useEffect(() => {
    let replaceString = props.headline && props.headline.replace('*', '<em>')
    if (replaceString) {
      const index = replaceString.lastIndexOf('*')
      replaceString =
        replaceString.slice(0, index) + replaceString.slice(index).replace('*', '&nbsp</em>')
      setHeadline(replaceString)
    }
  }, [props.headline])

  return (
    <>
      {contentBlocks?.length == 2 && (
        <div className="content-side-by-side-card">
          <div className="content-side-by-side-card__content">
            {props.headline && (
              <h3
                className="content-side-by-side-card__headline font-primary text-md mb-5 md:text-spg"
                style={{ color: `#${textColor}` }}
              >
                <ReactMarkdown rehypePlugins={[rehypeRaw]}>{props.headline}</ReactMarkdown>
              </h3>
            )}
            <div className="grid lg:grid-cols-2 gap-5 lg:gap-2.5">
              <div className="content-side-by-side-card__col flex w-full h-full">
                <ContentTextWithImageBlock {...contentBlocks[0]} />
              </div>
              <div className="content-side-by-side-card__col flex w-full h-full">
                <ContentTextWithImageBlock {...contentBlocks[1]} />
              </div>
            </div>
          </div>
        </div>
      )}
      <style jsx>{`
        .content-side-by-side-card {
          background-color: #${backgroundColor};
          padding: 40px 24px;
        }

        .content-side-by-side-card__headline {
          line-height: 18px;
          letter-spacing: -0.5px;
        }
        .content-side-by-side-card__content {
        }

        @media (min-width: ${styleConfig.theme.screens.md}) {
          .content-side-by-side-card {
            padding: 50px 24px;
          }

          .content-side-by-side-card__headline {
            line-height: 20px;
          }
        }

        @media (min-width: ${styleConfig.theme.screens.lg}) {
          .content-side-by-side-card {
            padding: 60px 24px;
          }

          .content-side-by-side-card__headline {
            font-size: 28px;
            line-height: 26px;
          }
        }

        @media (min-width: ${styleConfig.theme.screens.xl}) {
          .content-side-by-side-card {
            padding: 80px 60px;
          }

          .content-side-by-side-card__headline {
            font-size: 32px;
            line-height: 30px;
          }
        }
      `}</style>
    </>
  )
}

export default ContentSideBySideCard
